<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="processTable"
        title="취급공정 목록"
        tableId="processTable"
        :columns="gridprocess.columns"
        :data="gridprocess.data"
        :columnSetting="false"
        selection="multiple"
        rowKey="chemMaterialProcessId"
        :filtering="false"
        :usePaging="false"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addrowProcess" />
            <c-btn label="삭제" v-if="editable" icon="remove" @btnClicked="removeRowProcess" />
            <c-btn label="저장" v-if="editable" icon="save" @btnClicked="saveProcess" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-law',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      gridprocess: {
        columns: [],
        data: [],
      },
      processListUrl: '',
      processDeleteUrl: '',
      processSaveUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    'param.isSaved'() {
      this.getHeader();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.processListUrl = selectConfig.mdm.mam.chem.process.url;
      this.processDeleteUrl = transactionConfig.mdm.mam.chem.process.delete.url;
      this.processSaveUrl = transactionConfig.mdm.mam.chem.process.insert.url;
      
      this.$comm.getComboItems('VOLUME_UNIT').then(_result => {
        this.gridprocess.columns = [
          {
            name: 'upProcessName',
            field: 'upProcessName',
            label: '상위공정',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '제조 또는 사용여부',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N'
          },
          {
            name: 'usePurpose',
            field: 'usePurpose',
            label: '사용용도',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'dailyVolume',
            field: 'dailyVolume',
            label: '일 취급량',
            align: 'center',
            type: 'number',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'volumeUnit',
            field: 'volumeUnit',
            setHeader: true,
            required: false,
            label: '취급량 단위',
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
        ]
        this.getProcessList();
      });
    },
    getProcessList() {
      this.$http.url = this.processListUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.param.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.gridprocess.data = _result.data;
      },);
    },
    addrowProcess() {
      this.popupOptions.title = '단위공정 검색';
      this.popupOptions.param = {
        type: 'multiple',
        processLevelCd: '20'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/processPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeProcess;
    },
    closeProcess(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.gridprocess.data, { processCd: item.processCd }) === -1) {
            this.gridprocess.data.push({
              chemMaterialProcessId: uid(),
              mdmChemMaterialId: this.param.mdmChemMaterialId,
              processCd: item.processCd,
              processName: item.processName,
              upProcessName: item.upProcessName,
              useFlag: 'N',
              usePurpose: '',
              dailyVolume: '',
              editFlag: 'C',
            })
          }
        })
      }
    },
    saveProcess() {
      let saveData = this.gridprocess.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.processSaveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getProcessList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
    removeRowProcess() {
      let selectData = this.$refs['processTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.processDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getProcessList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');

              this.$_.forEach(selectData, item => {
                this.gridprocess.data = this.$_.reject(this.gridprocess.data, item);
              })
              this.$refs['processTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
